<mat-accordion fxLayout="row" fxLayoutAlign="center">
    <mat-expansion-panel [expanded]="false">
        <mat-expansion-panel-header style="color: #1C3775;">
            {{'FLEET-MANAGER.FORM-INFO' | translate}}
        </mat-expansion-panel-header>
        <mat-list role="list">
            <mat-list-item role="listitem">
                <p mat-line>1. {{'FLEET-MANAGER.STEP-1' | translate}}
                    <a class="link" libDebounceClick [attr.i18nKey]="'FLEET-MANAGER.NEED_DOC'"
                        (debounceClick)="downloadTemplate()">
                        {{'FLEET-MANAGER.NEED_DOC' | translate}}</a>.
                </p>
            </mat-list-item>
            <mat-list-item role="listitem">
                <p mat-line>2. {{'FLEET-MANAGER.STEP-2' | translate}} </p>
            </mat-list-item>
            <mat-list-item role="listitem">
                <p mat-line>3. {{'FLEET-MANAGER.STEP-3' | translate}} </p>
            </mat-list-item>
            <mat-list-item role="listitem">
                <p mat-line>4. {{'FLEET-MANAGER.STEP-4' | translate}}
                    <b>{{'FLEET-MANAGER.UPLOAD_FILE' | translate}}</b>
                </p>
            </mat-list-item>
            <mat-list-item role="listitem">
                <p mat-line>{{'FLEET-MANAGER.STEP-5' | translate}}</p>
            </mat-list-item>
        </mat-list>
    </mat-expansion-panel>
</mat-accordion>