import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ModalConfirmComponent } from 'src/app/components/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-form-field-person',
  templateUrl: './form-field-person.component.html',
  styles: [
  ]
})
export class FormFieldPersonComponent {
  @Input() personForm: FormGroup;
  @Input() readlOnlyMail: boolean;
  @Input() opacity: string;
  @Input() isRegister: boolean;

  constructor(
    private dialog: MatDialog,
    @Inject('regexFleetMailData') private regexMail: string) { }


  public mailFleetWarning(): void {
    if (!this.isRegister) { return; }
    const name = this.personForm.get('ctrlName');
    const surname = this.personForm.get('ctrlSurname');
    const mail = this.personForm.get('ctrlMail');
    var regexMail = new RegExp(this.regexMail);
    var valueRegex = regexMail.test(mail.value);
    const atIndex: string = mail.value.indexOf('@');
    // Prendi il sottostringa dall'inizio fino all'indice di "@"
    const username: string = mail.value.substring(0, atIndex);
    // verifica che l'inizio della mail non contenga il nome o il cognome e che la regex sia valida, in caso negativo apre la modale di warning
    if (!mail.invalid && !name.invalid && !surname.invalid && username &&
      (username.toLocaleLowerCase().includes(name.value.toLocaleLowerCase())
        || username.toLocaleLowerCase().includes(surname.value.toLocaleLowerCase()))
      && !valueRegex) {
      this.dialog.open(ModalConfirmComponent, {
        width: '50%',
        height: '30%',
        data: { text: 'FLEET-MANAGER.WARN_MAIL', onlyClose: true },
        autoFocus: false
      });
    }
  }

}
