<div fxLayout="column" *ngIf="!staticPage else static">
    <span fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
        <button mat-icon-button [matMenuTriggerFor]="menuLingua" aria-label="Example icon-button with a menu">
            <span>{{translateService.currentLang | slice : 0 : 2}}</span>
        </button>
        <mat-menu #menuLingua="matMenu">
            <span *ngFor="let lang of langs">
                <button mat-menu-item (click)="changeLang(lang)">
                    <a class="dropdown-item use-cursor-pointer"  translate>LANG.{{lang | uppercase}}</a>
                </button>
            </span>
        </mat-menu>
        <span fxFlex="1"></span>
    </span>
    <app-form-fleet-manager [register]="true"></app-form-fleet-manager>
</div>
<ng-template #static>
    <div fxLayout="row" fxLayoutAlign="center" style="padding-top: 25%">
        <mat-card-title>Pagina in sviluppo</mat-card-title>
    </div>
</ng-template>