<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px" *ngIf="!data.validForm else valid">
    <p> {{data.text | translate}}</p>
    <span *ngIf="data.uploadDoc">
        <p>{{fileDeposit?.name}}</p>
        <input style="display: none" type="file" [attr.i18nKey]="'depositInput'" #depositInput
            accept="application/pdf, image/jpeg, image/png" (change)="uploadDeposit($event)">
        <button mat-button class="uploadButtons" (click)="depositInput.click()">{{data.uploadDoc | translate}}</button>
    </span>
    <div *ngIf="data.onlyClose else actionsModal">
        <button mat-button class="whiteButtons" [attr.i18nKey]="'close'" (click)="dialogRef.close(false)"> 
            {{'COMMON.ACTION.CLOSE' | translate}} 
        </button>
    </div>
    <ng-template #actionsModal>
        <div fxLayout="row" fxLayoutGap="20px">
            <button mat-button class="whiteButtons" [attr.i18nKey]="'no'" (click)="dialogRef.close(false)"> no </button>
            <button mat-button class="actionButtons" [attr.i18nKey]="'COMMON.ACTION.YES'"
                (click)="dialogRef.close(fileDeposit ? {file: fileDeposit} : true)">
                {{'COMMON.ACTION.YES' | translate}}
            </button>
        </div>
    </ng-template>
</div>
<ng-template #valid>
    <div fxLayout="column" fxLayoutAlign="center">
        <mat-form-field appearance="outline" fxFlex="100" fxFlex.gt-xs="100" fxFlex.gt-lg="30" fxLayoutAlign="center">
            <mat-label>{{'FLEET-MANAGER.CONTRACT_CODE' | translate}}</mat-label>
            <input matInput type="text" [formControl]="contractCode" maxlength="7" required autocomplete="off">
            <mat-error>{{'FLEET-MANAGER.INSERT_CONTRACT_CODE' | translate}}</mat-error>
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center">
            <button mat-button class="whiteButtons" (click)="dialogRef.close(false)"
                [attr.i18nKey]="'COMMON.ACTION.BACK'"> {{'COMMON.ACTION.BACK' | translate}}
            </button>
            <button mat-button class="actionButtons" (click)="dialogRef.close(contractCode.value)"
                [disabled]="contractCode.status === 'INVALID'" [attr.i18nKey]="'COMMON.ACTION.SAVE'">
                {{'COMMON.ACTION.SAVE' | translate}}
            </button>
        </div>
    </div>
</ng-template>